import { useEffect, useState } from 'react'
import { graphql } from 'gatsby'
import queryString from 'query-string'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import { theme } from '@dfds-ui/theme'
import { SystemIcon } from '@dfds-ui/icons'
import { FlexBox } from '@dfds-ui/react-components/flexbox'
import { Button, Spinner, Headline } from '@dfds-ui/react-components'
import { track } from '@dfds-frontend/tracking'

import { FlexCard } from '../Card'
import { useLocationProvider } from '../LocationProvider/LocationProvider'
import { AspectImage } from '../Image'
import { unsubscribe } from './NewsLetterHelpers'
import { Markdown } from '../Markdown'
import { useSiteSettingsContext } from '../SiteSettings'

const StyledEmailContainer = styled(FlexBox)`
  margin: 0 0 20px;
  height: 40px;
  align-items: center;
  border: 1px solid ${theme.colors.text.dark.secondary};
  padding: 0 10px;

  svg {
    color: ${theme.colors.text.secondary.main};
  }

  p {
    margin: 0 5px;
  }
`

const NewsletterUnsubscribeBlock = (props) => {
  const { image, title, sorryToSeeYouGoText } = props
  const { t } = useTranslation()
  const [submitting, setSubmitting] = useState(false)
  const [hasUnsubscribed, setHasUnsubscribed] = useState(false)
  const [requestFailed, setRequestFailed] = useState(false)
  const { subscriptionManagementSettings } = useSiteSettingsContext()
  const location = useLocationProvider()

  const {
    EMail: email,
    businessArea,
    userProfileId,
    CDMUnsubscriptionId,
  } = queryString.parse(location.search)

  const submit = (props) => {
    setSubmitting(true)
    unsubscribe(props)
    track({
      event: 'newsletterUnsubscribed',
      emailCampaignId: CDMUnsubscriptionId || userProfileId,
    })
  }
  const webserviceUrl = `${process.env.GATSBY_BFF_URL}newsletter/unsubscribe`
  const unsubscribeProps = {
    oldWebserviceUrl:
      subscriptionManagementSettings.unsubscribeOldWebserviceUrl,
    webserviceUrl,
    email,
    businessArea: businessArea || null,
    userProfileId: userProfileId || null,
    CDMUnsubscriptionId: CDMUnsubscriptionId || null,
    setHasUnsubscribed,
    setSubmitting,
    setRequestFailed,
  }
  // Be aware we need state for this something weird with the button that doesn't hydrate properly
  // Also be aware it behaves differently for build vs develop
  const [disabled, setDisabled] = useState(false)
  useEffect(() => {
    setDisabled(!email)
  }, [email, setDisabled])

  return (
    <FlexCard
      mediaWidth={{ md: 8 }}
      media={image ? <AspectImage {...image} /> : null}
      reverse
    >
      <Headline as={'h2'}>{title}</Headline>
      {!hasUnsubscribed ? (
        <>
          {!requestFailed ? (
            <>
              <StyledEmailContainer>
                <SystemIcon icon="Mail" />
                <p>{email || 'Email is missing'}</p>
              </StyledEmailContainer>
              <div>
                <Button
                  onClick={() => submit(unsubscribeProps)}
                  type="submit"
                  fillWidth
                  disabled={disabled}
                >
                  {!submitting ? (
                    t('NEWSLETTER-UNSUBSCRIBE-BUTTON-TEXT')
                  ) : (
                    <Spinner />
                  )}
                </Button>
              </div>
            </>
          ) : (
            <p>{t('NEWSLETTER-UNSUBSCRIBE-ERROR-MESSAGE')}</p>
          )}
        </>
      ) : (
        <Markdown source={sorryToSeeYouGoText} />
      )}
    </FlexCard>
  )
}

export const NewsletterUnsubscribeBlockFragment = graphql`
  fragment NewsletterUnsubscribeBlock on contentful_NewsletterUnsubscribeBlock {
    __typename
    sys {
      id
    }
    title
    sorryToSeeYouGoText
    image {
      ...Asset
    }
  }
`

export default NewsletterUnsubscribeBlock
