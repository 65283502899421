export const getThankYouUrl = (businessArea) => {
  // remove when all newsletterSubscribeBlocks have filled out the thankYouPageUrl field
  if (businessArea === 'Passenger') {
    return 'http://dfds.com/en/passenger-ferries/thank-you'
  } else if (businessArea === 'Freight') {
    return 'http://dfds.com/en/freight-shipping/thank-you'
  } else if (businessArea === 'Logistics') {
    return 'http://dfds.com/en/logistics-solutions/thank-you'
  } else if (businessArea === 'Group') {
    return 'http://dfds.com/en/about/thank-you'
  }
  return 'http://dfds.com/en/about/thank-you'
}

export const subscribe = (props) => {
  const {
    webserviceUrl,
    postVerificationPageUrl,
    language,
    businessArea,
    cdmSourceId,
    email,
    setHasSubscribed,
    setSubmitting,
    setRequestFailed,
  } = props
  const headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  }

  const body = {
    source: cdmSourceId || 'DEFAULTDOTCOMVALUE',
    businessArea,
    locale: language,
    postVerificationPageUrl:
      postVerificationPageUrl || getThankYouUrl(businessArea),
    recipient: {
      email: email,
    },
  }

  return fetch(webserviceUrl, {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(body),
  }).then((r) => {
    if (r.status === 200 || r.status === 202) {
      setHasSubscribed(true)
      setSubmitting(false)
    } else {
      setRequestFailed(true)
      setSubmitting(false)
    }
  })
}

export const unsubscribe = (props) => {
  const {
    email,
    webserviceUrl,
    businessArea,
    userProfileId,
    CDMUnsubscriptionId,
    setHasUnsubscribed,
    setSubmitting,
    setRequestFailed,
  } = props
  let url, method

  if (CDMUnsubscriptionId?.length > 19 || userProfileId?.length > 19) {
    url = webserviceUrl
    method = 'POST'
  } else {
    setRequestFailed(true)
    return
  }

  const headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  }
  const body = {
    email: email,
    cdmSubscriberId: CDMUnsubscriptionId,
    userProfileId: userProfileId || CDMUnsubscriptionId || null,
    businessArea: businessArea || 'Passenger',
  }

  return fetch(url, {
    method,
    headers,
    body: JSON.stringify(body),
  }).then((r) => {
    if (r.status === 200 || r.status === 202) {
      setHasUnsubscribed(true)
      setSubmitting(false)
    } else {
      setRequestFailed(true)
      setSubmitting(false)
    }
  })
}
